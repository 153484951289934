<template>
	<div>
		<vx-card class="mb-base">
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<vs-input class="w-full" label="Domain" v-model="domainSearch['name']" @keyup.enter="search()" />
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<vs-input class="w-full" label="Domain ID" v-model="domainSearch['id']"  @keyup.enter="search()" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButtonDomainSearch" id="button-with-loading-domain-search"  class="mb-2 mr-4" @click="search()">Search</vs-button>
					<vs-button class="mb-2" color="danger" type="border" @click="resetDetails()">Reset</vs-button>
				</div>
			</div>
		</vx-card>

		<!-- No Results Alert -->
		<div v-if="searchFlags['searched'] && searchFlags['noResults']" class="vx-col w-full mb-base">
			<vs-alert active="true" icon="new_releases">No domains found for the above search criteria, please change the search criteria & try again.</vs-alert>
		</div>

		<!-- Domain Lifecycle Details -->
		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<vx-card id="domain-loader" class="mb-base vs-con-loading__container" v-for="(item, index) in domainDetails['lifecycle'].slice().reverse()" :key="index">
				<div slot="no-body">
					<div class="vx-card__header">
						<div class="vx-card__title">
							<h4>{{ `${domainLifecycleNumbers(index)}Domain details - ${domainDetails['name']}` }}</h4>
						</div>
						<div v-if="userRole == 'admin'" class="inline-block">
							<vs-button v-if="!searchFlags['unsuspended'] && showActionButton(index, item['status'], 'status') == 'unsuspend'" color="primary" type="border" size="small" @click="openUnsuspendPrompt(item['domain_id'])">Unsuspend</vs-button>
						</div>
					</div>
					<div class="vx-row p-6">
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain ID" :value="item['domain_id']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Creation Date" :value="item['transaction_date']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Period" :value="formatYears(item['period'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrar Name" :value="item['registrar_organization']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Name" :value="item['registrant_name']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Email" :value="item['registrant_email']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Country" :value="item['registrant_country']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Registrant Phone" :value="item['registrant_phone_voice']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Status" :value="formatStatus(item['status'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<label class="vs-input--label">Nameservers</label>
									<vs-textarea class="w-full label-input" :value="item['nameservers']" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Locked" :value="formatFlag(item['locked'])" disabled />
								</div>
							</div>
						</div>
						<div class="vx-col sm:w-1/3 w-full mb-4">
							<div class="vx-row">
								<div class="vx-col w-full">
									<vs-input class="w-full label-input" label="Domain Whitelisted" :value="formatFlag(item['whitelisted'])" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>

			</vx-card>
		</template>
		
		<!-- Abuse Log -->
		<vx-card v-show="searchFlags['searched'] && !searchFlags['noResults']" title="Abuse Log" class="mb-base">
			<div slot="no-body" class="p-6 pt-0">
				<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--small"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="domainLogs['abuseLog']"
					rowSelection="multiple"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:suppressRowClickSelection="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:sideBar="sideBar"
					:rowHeight="38"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
				</div>
			</div>
		</vx-card>

		<template v-if="searchFlags['searched'] && !searchFlags['noResults']">
			<!-- Unsuspensions -->
			<unsuspensions :user-role="userRole" :table-data="domainLogs['unsuspensionLog']" />
		</template>

		<!-- Unsuspend Prompt -->
		<vs-prompt id="unsuspend-prompt" vs-title="Unsuspend the selected domain?" :vs-is-valid="unsuspend['reason'] != ''" vs-accept-text="Unsuspend" @vs-cancel="closeUnsuspendPrompt" @vs-accept="unsuspendDomain" :vs-active.sync="unsuspend['prompt']">
			<div>
				<vs-textarea v-model="unsuspend['reason']" class="w-full" placeholder="Enter reason" />
			</div>
		</vs-prompt>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	// import "ag-grid-enterprise";
	import Unsuspensions from '@/components/ag-grid-tables/Unsuspensions.vue'

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
			Unsuspensions,
		},
		props: {
			paramId: {
				type: String,
				default: null
			},
			paramName: {
				type: String,
				default: null
			},
			userRole: {
				type: String,
				default: null
			},
		},
		data() {
			return {
				domainSearch: {
					name: '',
					id: '',
				},
				domainDetails: {
					name: '',
					tld: '',
					lifecycle: [],
				},
				searchFlags: {
					searched: false,
					noResults: false,
					unsuspended: false,
				},
				unsuspend: {
					prompt: false,
					reason: '',
					id: null
				},
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
						suppressMiniFilter: true,
					},
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Category',
					field: 'category',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Transaction Date',
					field: 'transaction_date',
					filter: "agTextColumnFilter",
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Detection Date',
					field: 'detection_date',
					filter: "agTextColumnFilter",
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Action Date',
					field: 'action_date',
					filter: "agTextColumnFilter",
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Registrar',
					field: 'registrar_organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Status',
					field: 'status',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['A','SH','SHP','SHF','PD','D'],
						suppressMiniFilter: true,
					},
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'A':
									return `<div class="badge badge--success inline">${data.value}</div>`
								case 'SH':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'SHP':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'SHF':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'PD':
									return `<div class="badge badge--info inline">${data.value}</div>`
								case 'D':
									return `<div class="badge badge--dark inline">${data.value}</div>`
								default:
									return `<div>-</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 100,
				},
				{
					headerName: 'Talos',
					field: 'suspicion_level',
					filter: "agTextColumnFilter",
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'high':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'medium':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'low':
									return `<div class="badge badge--primary inline">${data.value}</div>`
								default:
									return `<div>${data.value}</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 150,
				},
				{
					headerName: 'Whitelisted',
					field: 'whitelisted',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Locked',
					field: 'locked',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Reserved',
					field: 'reserved',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Reviewed',
					field: 'reviewed',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Manual',
					field: 'manual',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Nameservers',
					field: 'nameservers',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant ID',
					field: 'registrant_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant Email',
					field: 'registrant_email',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant Phone',
					field: 'registrant_phone_voice',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant State',
					field: 'registrant_state',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Registrant Country',
					field: 'registrant_country',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: "agTextColumnFilter",
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: "agTextColumnFilter",
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				domainLogs: {
					abuseLog: [],
					unsuspensionLog: [],
				},
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#domain-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#domain-loader > .con-vs-loading");
			},
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading-domain-search',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading-domain-search > .con-vs-loading");
			},
			domainLifecycleNumbers(index) {
				let length = this.domainDetails['lifecycle'].length;

				return length > 1 ? `${length - index}. `: '';
			},
			formatYears(years) {
				return years == 1 ? `${years} year` : `${years} years`;
			},
			formatStatus(status) {
				switch (status) {
					case 'SH':
						return 'Server Hold';
					case 'D':
						return 'Deleted';
					case 'PD':
						return 'Pending Delete';
					case 'SHF':
						return 'Server Hold Failed';
					case 'SHP':
						return 'Server Hold In Progress';
					default:
						return 'Active';
				}
			},
			formatFlag(flag) {
				if(flag) {
					return 'Yes'
				}
				else {
					return 'No'
				}
			},
			showActionButton(index, state, type) {
				if(index == 0) {
					if(type == 'status') {
						if (state == 'A') {
							return 'takedown';
						}
						else if (state == 'SH') {
							return 'unsuspend';
						}
						else {
							return false;
						}
					}
					else if (type == 'locked') {
						if (state == '0') {
							return 'lock';
						}
						else if (state == '1') {
							return 'unlock';
						}
						else {
							return false;
						}
					}
					else if (type == 'whitelisted') {
						if (state == '0') {
							return 'whitelist';
						}
						else if (state == '1') {
							return 'de-whitelist';
						}
						else {
							return false;
						}
					}
					else {
						return false;
					}
				}
				else {
					return false;
				}
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			resetDetails(flag) {
				if(flag != 'searched') {
					this.domainSearch = {
						name: '',
						id: '',
					};
					this.searchFlags = {
						searched: false,
						noResults: false,
					};
					this.$router.replace('/');
				}
				this.domainDetails = {
					name: '',
					tld: '',
					lifecycle: [],
				};
				this.domainLogs['abuseLog'] = [];
				this.domainLogs['unsuspensionLog'] = [];
			},
			populateDomainSearch(type, value) {
				if(type == 'id') {
					this.domainSearch['id'] = value;
					// Search for domain
					this.search();
				}
				else if(type == 'name') {
					this.domainSearch['name'] = value;
					// Search for domain
					this.search();
				}
			},
			search() {
				let self = this,
					name = this.domainSearch['name'],
					id = this.domainSearch['id'];

				if(id != '') {
					// Show loader
					this.showButtonLoading();
					// Search by Domain ID
					this.$axiosSecure.get('/domain-search-id', {
						params: {
							id: id
						}
					})
					.then((response) => {
						// Set Searched Flag
						this.searchFlags['searched'] = true;
						// Assign Data
						if(!response.data.noResults) {
							this.domainDetails = response.data.details;
							this.domainLogs['abuseLog'] = response.data.abuse;
							this.domainLogs['unsuspensionLog'] = response.data.unsuspension;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
						}
						else {
							this.resetDetails('searched');
							this.searchFlags['noResults'] = true;
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Confimation notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				}
				else if(name != '') {
					// Show loader
					this.showButtonLoading();
					// Search by Domain Name
					this.$axiosSecure.get('/domain-search-name', {
						params: {
							name: name
						}
					})
					.then((response) => {
						// Set Searched Flag
						this.searchFlags['searched'] = true;
						// Assign Data
						if(!response.data.noResults) {
							this.domainDetails = response.data.details;
							this.domainLogs['abuseLog'] = response.data.abuse;
							this.domainLogs['unsuspensionLog'] = response.data.unsuspension;
							// Reset noResults Flag
							this.searchFlags['noResults'] = false;
						}
						else {
							this.resetDetails('searched');
							this.searchFlags['noResults'] = true;
						}
						// Hide loader
						this.hideButtonLoading();
					})
					.catch((error) => {
						// Hide loader
						this.hideButtonLoading();
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				}
			},
			openUnsuspendPrompt(id) {
				// Store Unsuspend ID
				this.unsuspend['id'] = id;
				// Open unsuspend popup
				this.unsuspend['prompt'] = true;
			},
			closeUnsuspendPrompt() {
				// Close unsuspend popup
				this.unsuspend['prompt'] = false;
				// Clear input
				this.unsuspend['reason'] = '';
			},
			unsuspendConfirm(id) {
				// Store Unsuspend ID
				this.unsuspend['id'] = id;
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Unsuspend the selected domain?',
					accept: this.unsuspendDomain
				});
			},
			unsuspendDomain() {
				let self = this;

				// Show loader
				this.showDivLoading();

				// Post Axios Call
				this.$axiosSecure.post('/unsuspend-domain', {
					notes: this.unsuspend['reason'],
					domains: [{
						domain: this.domainDetails['name'],
						domain_id: this.unsuspend['id'],
						tld: this.domainDetails['tld']
					}],
					email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((response) => {
					if (response.data.errorArray.length) {
						// Open info popup
						self.$vs.dialog({
							type: 'confirm',
							color: 'primary',
							title: 'Please Note',
							text: 'The following domains were erroneous & hence ignored: '+response.data.errorArray.join(', '),
							accept: self.showUnsuspendSuccess,
							acceptText: 'Ok',
							buttonCancel: 'hidden'
						});
					}
					else {
						// Hide loader
						this.hideDivLoading();
						// Success Confirmation
						self.showUnsuspendSuccess();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			showUnsuspendSuccess() {
				// Confimation notification
				this.$vs.notify({
					color: 'success',
					title: 'Unsuspension success',
					text: 'The selected domains have been queued for unsuspension'
				});
				
				// Set Unsuspended flag
				this.searchFlags['unsuspended'] = true;
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			// this.sideBar = {
			// 	toolPanels: [
			// 	{
			// 		id: "columns",
			// 		labelDefault: "Columns",
			// 		labelKey: "columns",
			// 		iconKey: "columns",
			// 		toolPanel: "agColumnsToolPanel",
			// 		toolPanelParams: {
			// 			suppressRowGroups: true,
			// 			suppressValues: true,
			// 			suppressPivots: true,
			// 			suppressPivotMode: true,
			// 			suppressSideButtons: true,
			// 			suppressColumnSelectAll: true,
			// 			suppressColumnExpandAll: true
			// 		}
			// 	}
			// 	],
			// };
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Prepopulate Domain Name
			if(this.paramId) {
				this.populateDomainSearch('id', this.paramId);
			}
			else if(this.paramName) {
				this.populateDomainSearch('name', this.paramName);
			}
		}
	}
</script>
