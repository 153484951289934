<template>
	<div>
		<domain-details :user-role="user_role" :param-id="getUrlParameter('domain_id')" :param-name="getUrlParameter('domain_name')"/>
	</div>
</template>

<script>
	import DomainDetails from '@/components/search/DomainDetails.vue'

	export default {
		components: {
			DomainDetails
		},
		data() {
			return {
				searchView: 'domain',
			}
		},
		watch: {
			
		},
		computed: {
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			toggleSearchView(type) {
				this.searchView = type;
			},
			getUrlParameter(name) {
				return this.$route.query[name];
			},
		},
		mounted() {
			if(this.getUrlParameter('domain_id') || this.getUrlParameter('domain_name')) {
				this.searchView = 'domain';
			}
		}
	}
</script>
